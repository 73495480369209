<template>
  <input-new-password />
</template>

<script>
import inputNewPassword from "@/components/User/Auth/resetPassword/inputNewPassword.vue";
export default {
  components: { inputNewPassword },
};
</script>

<style>
</style>