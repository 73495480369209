<template>
  <v-main>
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackbarText" />
    <v-container style="padding: 0px">
      <v-form @submit.prevent="sendPassword">
        <v-row
          no-gutters
          align="start"
          justify="center"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'padding: 100px 0px'
              : 'padding: 50px 0px'
          "
        >
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'padding: 20px'
                : 'padding-right: 104px; padding-top: 12px'
            "
          >
            <img
              v-if="!$vuetify.breakpoint.smAndDown"
              src="@/assets/img/PTransLogoBlue.png"
              width="183px"
              alt="Logo"
            />
            <h3
              class="loginTitle"
              :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-top: 72px'"
            >
              Новий пароль
            </h3>
            <p class="loginText" style="margin: 8px 0px 0px 0px">
              Ваш попередній пароль було скинуто. Будь ласка, встановіть новий
              пароль для свого облікового запису.
            </p>
            <v-text-field
              style="margin-top: 20px; border-radius: 10px"
              dense
              outlined
              :label="'password_label' | localize"
              :placeholder="'type_here' | localize"
              color="#E2E2E2"
              background-color="#FFF"
              :type="isShowPassword ? 'text' : 'password'"
              height="56px"
              v-model="password.password"
              :error-messages="passwordError"
            >
              <template v-slot:append>
                <v-icon
                  style="margin-top: 8px"
                  color="#4B5262"
                  @click="isShowPassword = !isShowPassword"
                  >{{ isShowPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                >
                <v-icon
                  color="error"
                  style="margin-top: 8px; margin-left: 8px"
                  v-if="passwordError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-text-field
              style="margin-top: 0px; border-radius: 10px"
              dense
              outlined
              :label="'confirm_password_label' | localize"
              :placeholder="'type_here' | localize"
              color="#E2E2E2"
              background-color="#FFF"
              :type="isShowConfirmPassword ? 'text' : 'password'"
              height="56px"
              v-model="password.confirm_password"
              :error-messages="confirmPasswordError"
            >
              <template v-slot:append>
                <v-icon
                  style="margin-top: 8px"
                  color="#4B5262"
                  @click="isShowConfirmPassword = !isShowConfirmPassword"
                  >{{
                    isShowConfirmPassword ? "mdi-eye" : "mdi-eye-off"
                  }}</v-icon
                >
                <v-icon
                  color="error"
                  style="margin-top: 8px; margin-left: 8px"
                  v-if="confirmPasswordError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-btn
              class="detailBtn"
              width="100%"
              style="margin-top: 20px"
              type="submit"
            >
              Підтвердити
            </v-btn>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="6" class="py-0">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                v-for="i in 3"
                :key="i"
                :cssMode="true"
                :navigation="true"
                :pagination="true"
                :mousewheel="true"
                :keyboard="true"
                :modules="swiperOptions.modules"
              >
                <img
                  src="@/assets/img/routeImg.png"
                  width="100%"
                  height="100%"
                  class="loginImg"
                  style="user-select: none"
                />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>
      
      <script>
import swiperOptionMixin from "@/mixins/swiperOptionMixin";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import errorSnackbar from "@/components/UI/Snackbars/errorSnackbar.vue";
import successSnackbar from "@/components/UI/Snackbars/successSnackbar.vue";
import authService from "../../../../requests/Auth/authService";
export default {
  components: { errorSnackbar, successSnackbar },
  mixins: [swiperOptionMixin, validationMixin],
  data: () => ({
    password: {},
    successChangePassword: false,
    showPassword: false,
    showConfirmPassword: false,
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
    snackbarText: "",
    isShowPassword: false,
    isShowConfirmPassword: false,
  }),
  validations: {
    password: {
      password: {
        required,
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    sendPassword() {
      this.$v.$touch();
      if (!this.$v.password.$invalid) {
        let form = new FormData();
        form.append("token", this.$route.query.token);
        form.append("email", localStorage.getItem("userEmail"));
        form.append("password", this.password.password);
        form.append("password_confirmation", this.password.confirm_password);
        authService.changePassword(form).then(() => {
          this.$router.push("/login");
          // setTimeout(this.pushToMain, 1000);
          localStorage.clear();
        });
      }
    },
    // pushToMain() {
    //   this.$router.push("/");
    // },
  },
  computed: {
    passwordError() {
      const errors = [];
      if (!this.$v.password.password.$dirty) {
        return errors;
      }
      !this.$v.password.password.required && errors.push("");
      if (
        !this.$v.password.password.valid ||
        !this.$v.password.password.minLength
      ) {
        errors.push(
          this.$options.filters.localize("password_validation_error")
        );
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.confirm_password.required) {
        errors.push("");
        return errors;
      }
      !this.$v.password.confirm_password.sameAsPassword &&
        errors.push(
          this.$options.filters.localize("password_dont_match_error")
        );
      return errors;
    },
  },
};
</script>
      
      <style scoped>
.loginImg {
  border-radius: 30px;
}
.loginTitle {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.loginText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailBtn {
  border-radius: 10px;
  background: #144fa9 !important;
  color: #fafafa !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 48px !important;
  padding: 12px 16px;
  text-transform: none;
}
.continueWithSocialBackground {
  border-radius: 10px;
  border: 1px solid #989898;
  display: grid;
  place-items: center;
  width: 150px;
  height: 50px;
  cursor: pointer;
  user-select: none;
}
</style>
      <style>
.swiper-pagination-bullet-active {
  background: #fed500 !important;
  width: 32px !important;
  height: 10px !important;
  border-radius: 5px !important;
  transition: width 0.5s, border-radius 0.3s;
}
.swiper-pagination-bullet {
  background-color: #fafafa;
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>